<template>
  <div class="settings-access">
    <div v-if="$slots.legendCaption" class="settings-access__title">
      <slot name="legendCaption" />
    </div>
    <div :class="{'settings-access__content': $slots.legendCaption}">
      <template v-if="modeAddPermission">
        <div v-for="(itemField, indexField) in formAddPermission" :key="`fieldAddPermission${indexField}`" class="input-row settings-access__input-row">
          <SmartVSelect
            v-if="modeForDeviceAccessGroup"
            v-model="itemField.entityId"
            :settings-remote-search="settingsSelectDevice"
            class="settings-access__select"
            :placeholder="$t('selectDevicePlaceholder')"
          />
          <SmartVSelect
            v-else
            v-model="itemField.entityId"
            :settings-remote-search="settingsSelectDeviceAccessGroup"
            class="settings-access__select"
            :placeholder="$t('selectGroupPlaceholder')"
          />

          <div class="buttons-group">
            <SmartSelect
              v-model="itemField.permission"
              :options="permissionsForSelect"
              size="m"
            />
            <CamsButton
              v-if="lengthFormAddPermission > 1"
              class="settings-access__button-delete-field"
              icon-type="only"
              type="button"
              @click="deleteFieldFormAddPermission(indexField)"
            >
              <svg class="icon-close">
                <use xlink:href="#close" />
              </svg>
            </CamsButton>
          </div>
        </div>

        <div class="buttons-group input-row">
          <CamsButton
            v-if="!modeForDeviceAccessGroup"
            :disabled="lengthFormAddPermission >= 3"
            priority="primary"
            @click="addNewFieldFormAddPermission()"
          >
            {{ $t('more') }}
          </CamsButton>
          <CamsButton priority="primary" type="button" @click="addPermissions()">
            {{ $t('save') }}
          </CamsButton>
          <CamsButton type="button" @click="switchOffModeAddPermission()">
            {{ $t('cancel') }}
          </CamsButton>
        </div>
      </template>

      <template v-if="!modeAddPermission">
        <CamsButton
          class="input-row"
          priority="primary"
          type="button"
          @click="switchOnModeAddPermission()"
        >
          {{ modeForDeviceAccessGroup ? $t('addDevice') : $t('addGroup') }}
        </CamsButton>

        <div class="input-row">
          <SmartInputText
            v-model="search"
            :placeholder="modeForDeviceAccessGroup ? $t('deviceFilter') : $t('groupFilter')"
            type="text"
            @input="debouncedLoadPermissions()"
          />
        </div>
      </template>

      <div class="input-row">
        <SpinnerLoading v-if="isLoading" size="s" />

        <div v-else-if="isAvailablePermissions" class="access-container">
          <div class="access-container__header">
            <div>{{ modeForDeviceAccessGroup ? $t('deviceHeader') : $t('groupHeader') }}</div>
            <div>{{ $t('accessLevel') }}</div>
          </div>

          <div class="access-container__content">
            <div v-for="(permissionInfo, permissionInfoIndex) in permissionsInfo" :key="permissionInfoIndex" class="access-container__list-item">
              <div>{{ permissionInfo.title }}</div>

              <div class="buttons-group">
                <SmartSelect
                  v-model="permissionInfo.permission"
                  :options="permissionsForSelect"
                  size="s"
                  @input="updatePermission(permissionInfo.deviceId, permissionInfo.deviceAccessGroupId, permissionInfo.isAuto, $event)"
                />
                <CamsButton
                  class="settings-access__button-delete-field"
                  icon-type="only"
                  type="button"
                  @click="deletePermission(permissionInfo.deviceId, permissionInfo.deviceAccessGroupId, permissionInfo.isAuto)"
                >
                  <svg class="icon-close">
                    <use xlink:href="#close" />
                  </svg>
                </CamsButton>
              </div>
            </div>
          </div>

          <div>
            <nav v-if="pageInfo.numPages > 1" class="pagination-small">
              <paginate
                v-model="currentPage"
                :active-class="'pagination-small__list-item--active'"
                :break-view-class="'pagination-small__list-item--collapse'"
                :click-handler="loadPermissions"
                :container-class="'pagination-small__list'"
                :hide-prev-next="true"
                :page-class="'pagination-small__list-item'"
                :page-count="pageInfo.numPages"
                :page-range="5"
                next-text=""
                prev-text=""
              />
            </nav>

            <div class="pagination-small__count">
              {{ $t('foundCount') }} {{ pageInfo.count }}
            </div>
          </div>
        </div>

        <div v-else>
          <p>{{ $t('noPermissions') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setPermissionsMixin} from "@/components/pacs/pacsPermissions/mixin.js";
import {ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT, DEVICE_ACCESS_GROUP_STRUCT} from "@/store/pacs/deviceAccessGroups/index.js";
import {ACTION_LOAD_DEVICES_FOR_SELECT, DEVICE_STRUCT} from "@/store/pacs/devices/index.js";
import {
  ACTION_ADD_DEVICE_ACCESS_GROUPS_PERMISSIONS_BY_FORM,
  ACTION_DELETE_DEVICE_ACCESS_GROUPS_PERMISSIONS,
  ACTION_LOAD_DEVICE_ACCESS_GROUP_PERMISSIONS_FOR_EDIT,
  DEVICE_ACCESS_GROUP_PERMISSION_STRUCT, DEVICES_PERMISSIONS,
} from "@/store/pacs/pacsPermissions/index.js";

/**
 * Компонент диалога установки прав на группу доступа (группу сотрудников) для устройств.
 *
 * Предусматривает два режима:
 *  - для управления правами многих устройств для указанной группы;
 *  - для управления правами многих групп для указанного устройства;
 *
 * Режим зависит от переданного идентификатора в конкретный параметр (ID устройства или группы - приоритет для группы).
 * Возможность множественного добавления прав предназначена для одного устройства.
 */
export default {
  mixins: [setPermissionsMixin],
  props: {
    /**
     * Идентификатор группы доступа - позволяет управлять правами разных устройств для этой группы.
     */
    deviceAccessGroupId: {
      type: Number,
      default: null
    },
    /**
     * Идентификатор устройства - позволяет управлять правами разных групп доступа для этого устройства.
     */
    deviceId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      settingsSelectDevice: {
        action: `pacs/devices/${ACTION_LOAD_DEVICES_FOR_SELECT}`,
        valueField: DEVICE_STRUCT.fields.id.name,
        labelField: DEVICE_STRUCT.fields.title.name
      },
      settingsSelectDeviceAccessGroup: {
        action: `pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT}`,
        valueField: DEVICE_ACCESS_GROUP_STRUCT.fields.id.name,
        labelField: DEVICE_ACCESS_GROUP_STRUCT.fields.title.name
      },
    };
  },
  computed: {
    /**
     * Вернет true для режима, в котором права устанавливаются для конкретной группы доступа, а настраиваются устройства.
     *
     * @return {Boolean}
     */
    modeForDeviceAccessGroup() {
      return this.deviceAccessGroupId !== null;
    },
  },
  created() {
    this.debouncedLoadPermissions = _.debounce(this.loadPermissions, 350);
    this.loadPermissions();
    this.getPermissionsForSelect();
  },
  methods: {
    getPermissionsForSelect() {
      return this.permissionsForSelect ={
        [DEVICES_PERMISSIONS.ACCESS]: this.$t('fullAccess'),
        [DEVICES_PERMISSIONS.ENTRANCE]: this.$t('entrance'),
        [DEVICES_PERMISSIONS.EXIT]: this.$t('exit'),
      };
    },
    /**
     * Загрузит информацию по правам для редактирования.
     *
     * @param {Number} currentPage
     */
    async loadPermissions(currentPage = 1) {
      this.currentPage = this.currentPage === currentPage ? this.currentPage : currentPage;
      // Основной фильтр зависит от режима работы компонента.
      const mainFilter = this.modeForDeviceAccessGroup ? {deviceAccessGroupId: this.deviceAccessGroupId} : {deviceId: this.deviceId};

      this.isLoading = true;
      try {
        const responseData = await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_LOAD_DEVICE_ACCESS_GROUP_PERMISSIONS_FOR_EDIT}`, {
                ...mainFilter,
                search: this.search || "",
                page: currentPage
              }),
              titlesForPermissions = this.modeForDeviceAccessGroup
                ? _.chain(responseData.extra[DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.extras.device])
                  .keyBy(DEVICE_STRUCT.fields.id.name)
                  .mapValues(DEVICE_STRUCT.fields.title.name)
                  .value()
                : _.chain(responseData.extra[DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.extras.device_access_group])
                  .keyBy(DEVICE_ACCESS_GROUP_STRUCT.fields.id.name)
                  .mapValues(DEVICE_ACCESS_GROUP_STRUCT.fields.title.name)
                  .value();

        this.pageInfo.count = responseData.count;
        this.pageInfo.numPages = responseData.page.all;
        this.permissionsInfo = responseData.results.map((rawPermissionInfo) => {
          return {
            deviceAccessGroupId: rawPermissionInfo[DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.device_access_group_id.name],
            permission: rawPermissionInfo[DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.access_type.name],
            deviceId: rawPermissionInfo[DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.device_id.name],
            isAuto: rawPermissionInfo[DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.is_auto.name],
            title: titlesForPermissions[rawPermissionInfo[this.modeForDeviceAccessGroup ? DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.device_id.name : DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.device_access_group_id.name]],
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Обработка формы для добавления прав и оформление запроса по заполненным данным.
     */
    async addPermissions() {
      const dataForSave = {},
            valuesFormAddPermission = _.values(this.formAddPermission);

      if (this.modeForDeviceAccessGroup) {
        // Если задана группа доступа, то в списке прав заданы устройства (1) и их права.
        dataForSave.deviceAccessGroupId = this.deviceAccessGroupId;
        dataForSave.rawPermissionsInfo = valuesFormAddPermission.map((rawPermissionInfo) => {
          return (rawPermissionInfo.entityId && rawPermissionInfo.permission) ? [rawPermissionInfo.entityId, rawPermissionInfo.permission] : null;
        });
        dataForSave.rawPermissionsInfo = _.filter(dataForSave.rawPermissionsInfo);

        if (_.isEmpty(dataForSave.rawPermissionsInfo)) {
          this.$camsdals.alert(this.$t('errorAddingPermissions'));
          return;
        }
      } else {
        // Если задано устройство, то в списке прав заданы группы доступа и их права.
        const rawPermissionInfo = _.head(valuesFormAddPermission);
        if (!rawPermissionInfo.entityId) {
          this.$camsdals.alert(this.$t('errorAddingPermissions'));
          return;
        }

        dataForSave.deviceAccessGroupId = rawPermissionInfo.entityId;
        dataForSave.rawPermissionsInfo = [
          [this.deviceId, rawPermissionInfo.permission]
        ];
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_ADD_DEVICE_ACCESS_GROUPS_PERMISSIONS_BY_FORM}`, dataForSave);
        this.switchOffModeAddPermission();
      } catch {
        this.$camsdals.alert(this.$t('errorAddingPermissions'));
      }
      this.isLoading = false;
    },
    /**
     * Редактирование (установка) прав для найденных.
     *
     * @param {Number} deviceId
     * @param {Number} deviceAccessGroupId
     * @param {Boolean} isAuto
     * @param {Number} permission
     */
    async updatePermission(deviceId, deviceAccessGroupId, isAuto, permission) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_ADD_DEVICE_ACCESS_GROUPS_PERMISSIONS_BY_FORM}`, {
          deviceAccessGroupId,
          isAuto,
          rawPermissionsInfo: [[deviceId, permission]],
          mergePermissions: false,
        });
      } catch {
        this.$camsdals.alert(this.$t('errorUpdatingPermissions'));
      }
      this.loadPermissions(this.currentPage);
      this.isLoading = false;
    },
    /**
     * Удаление конкретных прав из списка найденных.
     *
     * @param {Number} deviceId
     * @param {Number} deviceAccessGroupId
     * @param {Boolean} isAuto
     */
    deletePermission(deviceId, deviceAccessGroupId, isAuto) {
      this.$camsdals.confirm(this.$t('confirmDeletePermission'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_DELETE_DEVICE_ACCESS_GROUPS_PERMISSIONS}`, {
            deviceAccessGroupId,
            isAuto,
            deviceIds: [deviceId]
          });
          this.$notify({
            group: "main",
            text: this.$t('permissionDeleted'),
            duration: 5000,
            type: "default"
          });
        } catch {
          this.$camsdals.alert(this.$t('errorDeletingPermissions'));
        }
        this.loadPermissions();
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
@use "./style.scss";
</style>
