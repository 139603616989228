<template>
  <aside class="sidebar-container">
    <nav>
      <ul class="sidebar-container__menu">
        <li class="sidebar-container__menu-item">
          <a :href="logoLink || '/'" class="sidebar-container__logo">
            <img v-if="logo" :src="logo" alt="" class="sidebar-container__logo-image">
            <svg v-else class="sidebar-container__logo-image sidebar-container__logo-image_default">
              <use xlink:href="../assets/img/icons.svg#logo-client" />
            </svg>
          </a>
        </li>
        <li class="sidebar-container__menu-item" :title="$t('tableHeaders.cameras')">
          <router-link :to="actualRoutesSections[routes.ROUTE_CAMS]">
            <svg class="icon icon-menu-camera">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-menu-camera" />
            </svg>
          </router-link>
        </li>
        <li class="sidebar-container__menu-item" :title="$t('multiscreen')">
          <router-link :to="actualRoutesSections[routes.ROUTE_MULTI_SCREEN]">
            <svg class="icon icon-menu-multiscreen">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-menu-multiscreen" />
            </svg>
          </router-link>
        </li>
        <li class="sidebar-container__menu-item" :title="$t('map')">
          <router-link :to="actualRoutesSections[routes.ROUTE_MAP]">
            <svg class="icon icon-menu-location">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-menu-location" />
            </svg>
          </router-link>
        </li>
        <li v-if="availablePacs" class="sidebar-container__menu-item" :title="$t('scud')">
          <router-link :to="actualRoutesSections[routes.ROUTE_PACS]">
            <svg class="icon icon-menu-pacs">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-menu-pacs" />
            </svg>
          </router-link>
        </li>
        <li class="sidebar-container__menu-item" :title="$t('setupCameras')">
          <router-link :to="actualRoutesSections[routes.ROUTE_SETUP]">
            <svg class="icon icon-menu-pacs">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-menu-setup" />
            </svg>
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import {ROUTE_CAMS, ROUTE_MAP, ROUTE_MULTI_SCREEN, ROUTE_PACS, ROUTE_SETUP} from "@/router/names.js";
import {mapGetters, mapState} from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      routes: {
        ROUTE_CAMS,
        ROUTE_MULTI_SCREEN,
        ROUTE_MAP,
        ROUTE_PACS,
        ROUTE_SETUP,
      },
    };
  },
  computed: {
    ...mapState(["actualRoutesSections"]),
    ...mapGetters(["logo", "logoLink", "availablePacs"]),
  },
};
</script>

<style lang="scss">
@use "./scss/sidebar.scss";
</style>
