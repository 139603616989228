<template>
  <div class="common-container__body">
    <div class="common-container__wrap">
      <div class="main__header">
        <div class="tabs-container">
          <router-link :to="{ name: routes.ROUTE_PACS_EMPLOYEES }" class="tabs-container__tab-item">
            {{ $t('employees') }}
          </router-link>
          <router-link :to="{ name: routes.ROUTE_PACS_DEVICES }" class="tabs-container__tab-item">
            {{ $t('accessDevices') }}
          </router-link>
          <router-link :to="{ name: routes.ROUTE_PACS_DEVICE_ACCESS_GROUPS }" class="tabs-container__tab-item">
            {{ $t('accessGroups') }}
          </router-link>
          <router-link :to="{ name: routes.ROUTE_PACS_CARS }" class="tabs-container__tab-item">
            {{ $t('cars') }}
          </router-link>
          <router-link :to="{ name: routes.ROUTE_EVENTS }" class="tabs-container__tab-item">
            {{ $t('events') }}
          </router-link>
          <!--          <router-link :to="{name: routes.ROUTE_PACS_ANALYTICS_SETTINGS}" class="tabs-container__tab-item">-->
          <!--            Настройка аналитик-->
          <!--          </router-link>-->
        </div>
      </div>

      <router-view />
    </div>
  </div>
</template>

<script>
import {
  ROUTE_EVENTS,
  ROUTE_PACS_CARS,
  ROUTE_PACS_DEVICE_ACCESS_GROUPS,
  ROUTE_PACS_DEVICES,
  ROUTE_PACS_ANALYTICS_SETTINGS,
  ROUTE_PACS_EMPLOYEES
} from "@/router/names.js";

export default {
  name: "PacsFrame",
  data() {
    return {
      routes: {
        ROUTE_PACS_EMPLOYEES,
        ROUTE_PACS_DEVICES,
        ROUTE_PACS_DEVICE_ACCESS_GROUPS,
        ROUTE_PACS_CARS,
        ROUTE_EVENTS,
        ROUTE_PACS_ANALYTICS_SETTINGS,
      },
    };
  },
};
</script>

<style lang="scss">
@use "./style.scss";
</style>
