<template>
  <header class="top-menu">
    <h1 class="top-menu__title">
      {{ title }}
    </h1>

    <div class="top-menu__username">
      <CamsButton
        class="button-settings"
        icon-type="only"
        @click="toggleOptions"
      >
        <svg class="icon icon-settings">
          <use xlink:href="../assets/img/icons.svg#icon-settings" />
        </svg>
        <span class="button-settings__username"> {{ username }}</span>
      </CamsButton>
    </div>
    <!-- Dropdown menu -->
    <div v-if="showDropdown" ref="dropdownMenu" class="top-menu__dropdown">
      <CamsButton
        class="button-account"
        @click="openDialogSetLinksForClients"
      >
        {{ $t('linkedAccounts') }}
      </CamsButton>
      <CamsButton
        class="button-account"
        @click="openDialogGetToken"
      >
        {{ $t('accountAccess') }}
      </CamsButton>
      <CamsButton
        v-if="vendorIsBox"
        class="button-account"
        @click="openDialogSetLanguage"
      >
        {{ $t('language') }} : {{ selectedLanguageLabel }}
      </CamsButton>
      <CamsButton class="button-exit" @click="signOut">
        {{ $t('exit') }}
      </CamsButton>
    </div>
  </header>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import SetLinksForClientsDialog from "@/components/users/SetLinksForClientsDialog.vue";
import GetTokenDialog from "@/components/users/GetTokenDialog.vue";
import SetLanguageDialog from "@/components/users/SetLanguageDialog.vue";
import {VENDOR_IS_BOX} from "@/utils/consts.js";

export default {
  name: "TopMenu",
  data() {
    return {
      showDropdown: false,
      vendorIsBox: VENDOR_IS_BOX,
      selectedLanguage: localStorage.getItem('locale') || 'ru', // Получаем язык из localStorage или устанавливаем по умолчанию 'ru'
      languages: [
        {value: 'ru', label: 'Русский'},
        {value: 'en', label: 'English'},
        {value: 'tr', label: 'Türkçe'}
      ],
    };
  },
  computed: {
    selectedLanguageLabel() {
      // Возвращаем метку текущего языка
      const lang = this.languages.find(lang => lang.value === this.selectedLanguage);
      return lang ? lang.label : '';
    },
    ...mapState({
      title: (state) => state.context.title,
      username: (state) => state.username
    }),
    ...mapGetters(["urlSignOut"])
  },
  methods: {
    toggleOptions(event) {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        document.addEventListener("click", this.closeDropdownMenu);
      } else {
        document.removeEventListener("click", this.closeDropdownMenu);
      }
      event.stopPropagation();
    },
    openDialogSetLinksForClients() {
      this.$camsdals.open(
        SetLinksForClientsDialog,
        {},
        {dialogTitle: this.$t('accountRelationsSettings')},
      );
    },
    openDialogGetToken() {
      this.$camsdals.open(
        GetTokenDialog,
        {},
        {},
        {closeOnBackdrop: false,
         size: "vuedal-auto-width",
         name: "js-click-outside",},
      );
    },
    openDialogSetLanguage() {
      this.$camsdals.open(
        SetLanguageDialog,
        {},
        {dialogTitle: this.$t('languageSelection')},
        {closeOnBackdrop: false,
         size: "vuedal-auto-width",
         name: "js-click-outside",},
      );
    },
    closeDropdownMenu(event) {
      if (!this.$refs.dropdownMenu.contains(event.target)) {
        this.showDropdown = false;
        document.removeEventListener("click", this.closeDropdownMenu);
      }
    },
    signOut() {
      window.location.href = this.urlSignOut;
    }
  }
};
</script>

<style lang="scss">
@use "./scss/top-menu.scss";
</style>
