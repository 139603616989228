<template>
  <div class="multiscreen-grid-cell" @mouseover="showSettings = true" @mouseleave="showSettings = false">
    <div
      v-if="!cameraInfo "
      class="multiscreen-grid-cell__container multiscreen-grid-cell__container_empty"
      :class="{'multiscreen-grid-cell__container_alarm': alarmMode}"
    >
      <div v-show="isEditable" class="multiscreen-grid-cell__select-camera" @click="openSelectCameraDialog()">
        <svg class="icon-add">
          <use xlink:href="/img/symbol-defs-v2.svg#icon-add" />
        </svg>
        <p>{{ $t('addCamera') }}</p>
      </div>

      <div v-show="isEditable && showSettings" class="multiscreen-grid-cell-settings" style="color: #3e4347">
        <SmartSwitch v-model="alarmMode" @input="$emit('change-alarm-mode', $event)" />
        <span class="multiscreen-grid-cell-settings__top-item-caption">{{ $t('alarmCell') }}</span>
      </div>
    </div>
    <div
      v-else
      :class="{'multiscreen-grid-cell__container_alarm': alarmMode}"
      class="multiscreen-grid-cell__container multiscreen-grid-cell__container_active"
    >

      <SmartPlayer
        v-if="cameraInfo.isReadyForLive && !isArchiveMode"
        :archive-token="cameraInfo.tokenDVR"
        :auto-shift-mode="false"
        :camera-number="cameraInfo.number"
        :domain="cameraInfo.server.domain"
        :has-sound="cameraInfo.hasSound"
        :vendor-name="cameraInfo.server.vendor_name"
        :hot-keys-enabled="false"
        :http-protocol="$store.getters.protocolVideoOverHTTP"
        :initial-low-latency-mode="lowLatencyMode"
        :has-ptz="cameraInfo.isPtz.is_ptz"
        :initial-stream-number="streamNumber"
        :live-token="cameraInfo.tokenLive"
        :stream-count="cameraInfo.streamsCount"
        :use-simple-interface="true"
        :ws-protocol="$store.getters.protocolVideoOverWS"
        :available-archive-fragments="availableArchiveFragments"
        :get-line-fragments="getLineFragments"
        class="multiscreen-grid-cell__player"
        :on-p-t-z-start="debouncedStartPTZ"
        :on-p-t-z-stop="stopPTZ"
        :on-p-t-z-centralize="centralizePTZ"
        :locale="this.locale"
        @disaster="$emit('need-refresh', cameraInfo.number)"
      />
      <p v-else>
        {{ $t('streamUnavailable') }}
      </p>
      <div
        v-show="cameraInfo"
        :title="cameraInfo.title"
        class="multiscreen-grid-cell__camera-title"
        v-text="cameraInfo.title"
      >

      </div>
      <CamsButton
        v-show="this.showSettings"
        icon-type="only"
        type="button"
        class="multiscreen-grid-cell__camera-archive"
        :class="{'multiscreen-grid-cell__camera-archive-icon': isArchiveMode}"
        @click.stop="openOneScreenDialog(cameraInfo.number,cameraInfo.title)"
      >
        <svg fill="white" v-show="!isArchiveMode">
          <use xlink:href="../../assets/img/icons.svg#cloud-archive" />
        </svg>
        <svg v-show="isArchiveMode" fill="white" >
          <use xlink:href='#icon-arrow-left'>
          </use></svg>
      </CamsButton>
      <div v-show="cameraInfo && alarmMode" class="multiscreen-grid-cell__alarm">
        <svg class="icon">
          <use xlink:href="/img/symbol-defs-v2.svg#icon-alarm" />
        </svg>
        <span>{{ $t('alarm') }}</span>
      </div>

      <div v-show="isEditable" class="multiscreen-grid-cell-settings">
        <button
          class="multiscreen-grid-cell-settings__top-item"
          :title="$t('deleteCamera')"
          type="button"
          @click="deleteCamera()"
        >
          <svg class="icon">
            <use xlink:href="#icon-close" />
          </svg>
        </button>
        <button
          class="multiscreen-grid-cell-settings__top-item"
          :title="$t('changeCamera')"
          type="button"
          @click="openSelectCameraDialog()"
        >
          <svg class="icon">
            <use xlink:href="/img/symbol-defs-v2.svg#icon-menu-camera" />
          </svg>
        </button>
        <button
          class="multiscreen-grid-cell-settings__top-item grabbable"
          :title="$t('moveToAnotherCell')"
          type="button"
        >
          <svg class="icon">
            <use xlink:href="#icon-grabbable" />
          </svg>
        </button>
        <div class="multiscreen-grid-cell-settings__top-item">
          <span class="multiscreen-grid-cell-settings__top-item-caption">{{ $t('alarmCell') }}</span>
          <SmartSwitch v-model="alarmMode" @input="$emit('change-alarm-mode', $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeshFrameDialog from "@/components/meshCameras/MeshFrameDialog.vue";
import {
  ACTION_GET_DOWNLOAD_TOKEN, ACTION_LOAD_CAMERA_FOR_PLAYER,
  ACTION_LOAD_RECORDING_STATUSES,
  ACTION_PTZ_CAMERA,
  CameraInfo
} from "@/store/cameras/index.js";
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {
  MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
  MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
  TOKEN_TTL,
  VUEX_CACHE_TTL
} from "@/utils/consts.js";
import {whenToUpdateToken} from "@/utils/helpers.js";
import {exitFullscreen} from "camsng-frontend-shared/lib/helpers";
import {QUERY_KEY_ONE_SCREEN_TAB, QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/router/queryKeys.js";
import OneScreenDialog from "@/components/oneScreen/OneScreenDialog.vue";

/**
 * Компонент для ячейки в сетке мозаики.
 *
 * Предназначен для отображения потока выбранной камеры и интерфейса ее настройки или ее выбора.
 */
export default {
  name: "GridCell",
  components: {
    SmartPlayer,
  },
  props: {
    /**
     * Объект камеры {@link CameraInfo} по которому будет организована трансляция.
     */
    initialCameraInfo: {
      type: CameraInfo,
      default: null,
    },
    /**
     * Начальное положение флага для работы в режиме тревожной ячейки.
     */
    initialAlarmMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Флаг для включения опций редактирования.
     */
    isEditable: {
      type: Boolean,
      default: false,
    },
    /**
     * Поток по умолчанию по которому будет запрошена и воспроизводится трансляция. Зависит от сетки мозаики.
     */
    streamNumber: {
      type: Number,
      default: 2,
    },
    /**
     * Флаг для включения плеера режиме низкой задержки.
     */
    lowLatencyMode: {
      type: Boolean,
      default: true,
    },
    showMediator: {
      type: Boolean,
      default: false,
    },
    currentArchiveCamera: {
      type: String,
      default: null,
    },
    cameraId: {
      type: String,
    },
  },
  data() {
    const initialTimeShift = new Date();
    initialTimeShift.setMinutes(initialTimeShift.getMinutes() - 10, 0, 0);
    return {
      cameraInfo: null,
      locale: localStorage.getItem('locale'),
      refreshTimeoutId: null,
      alarmMode: this.initialAlarmMode,
      availableArchiveFragments: [],
      timeoutIdForReceivingRecordingStatus: null,
      ptzInterval: null,
      minUnixDownloadDelta: MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
      maxUnixDownloadDelta: MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
      showSettings: false,
      useSimpleInterface: true,
      isArchiveMode: false,
      initialTimeShift: null,
    };
  },
  watch: {
    currentArchiveCamera(newId) {
      if (newId !== this.cameraId && this.isArchiveMode) {
        // Если другой плеер перешел в архивный режим, закрываем этот
        this.exitArchiveMode();
      }
    },
    /**
     * Отслеживание изменений в передаваемом параметре объекта камер для корректного обновления содержимого ячейки сетки.
     *
     * @param {CameraInfo} newCameraInfo
     */
    initialCameraInfo(newCameraInfo) {
      this.setupCamera(newCameraInfo);
    },
    /**
     * Отслеживание внешнего флага тревоги для актуального локального значения.
     *
     * @param {Boolean} newAlarmMode
     */
    initialAlarmMode(newAlarmMode) {
      if (newAlarmMode !== this.alarmMode) {
        this.alarmMode = this.initialAlarmMode;
      }
    },
    showMediator(newShowMediator) {
      if (newShowMediator) {
        this.receiveRecordingStatus();
      } else {
        clearTimeout(this.timeoutIdForReceivingRecordingStatus);
        this.availableArchiveFragments = [];
      }
    },
  },
  /**
   * Инициализация начального состояния ячейки.
   */
  created() {
    this.setupCamera(this.initialCameraInfo);
    this.debouncedStartPTZ = _.debounce(this.startPTZ, 0.1);
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);

  },
  /**
   * Очистка таймаута для обновления камеры.
   */
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
    clearTimeout(this.refreshTimeoutId);
    clearTimeout(this.timeoutIdForReceivingRecordingStatus);
  },
  methods: {
    async resetPlayerToLive() {
      if (this.cameraInfo?.isReadyForLive) {
        await this.$nextTick()
      }
      this.isArchiveMode = false; // Выключаем архивный режим
      this.useSimpleInterface = true; // Восстанавливаем интерфейс для лайв-режима
    },
    enterArchiveMode() {
      this.useSimpleInterface = false;
      this.isArchiveMode = true;
      if (this.alarmMode) {
        this.alarmMode = false;
        this.$emit("change-alarm-mode", this.alarmMode); // Уведомляем родительский компонент
      }

      this.$emit("set-archive-camera", this.cameraId); // Уведомляем родителя
      this.receiveRecordingStatus(); // Начинаем запрашивать архивные данные

    },
    async exitArchiveMode() {
      this.useSimpleInterface = true;
      this.isArchiveMode = false;
      clearTimeout(this.timeoutIdForReceivingRecordingStatus);
      this.availableArchiveFragments = [];
      if (this.initialAlarmMode) {
        this.alarmMode = this.initialAlarmMode;
        this.$emit("change-alarm-mode", this.alarmMode); // Уведомляем родительский компонент
      }
      await this.resetPlayerToLive(); // Сбрасываем состояние плеера
      // Выходим из полноэкранного режима
        if (document.exitFullscreen) {
          await exitFullscreen()
        }
    },
    handleFullscreenChange() {
      if (!document.fullscreenElement && this.isArchiveMode) {
        this.exitArchiveMode();
      }
    },
    toggleArchiveMode() {
      try {
        if (this.isArchiveMode) {
          this.exitArchiveMode();
        } else {
          this.enterArchiveMode();
        }
      } catch (err) {
        console.warn("Error toggling archive mode:", err);
      }
    },
    async downloadVideo([getterDownloadUrl, unixDownloadFrom, unixDownloadDelta]) {
      // Конкретные ограничения по длительности запрашиваемого видео.
      if ((unixDownloadDelta < MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER) || (unixDownloadDelta > MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER)) {
        this.$camsdals.alert(this.$t('invalidDownloadRange'));
        return;
      }
      let tokenDownload = null;
      this.isLoadingDownloadToken = true;
      try {
        const token = await this.$store.dispatch(`cameras/${ACTION_GET_DOWNLOAD_TOKEN}`, {
          number: this.cameraNumber,
          tokenDownloadDuration: unixDownloadDelta,
          tokenDownloadStart: unixDownloadFrom,
        });
        tokenDownload = token;
      } catch (error) {
        devLog("[downloadVideo]", error);
      }
      if (tokenDownload) {
        this.downloadUrl = getterDownloadUrl(tokenDownload);
      } else {
        this.$camsdals.alert(this.$t('downloadError'));
      }
      this.isLoadingDownloadToken = false;
    },
    async getLineFragments(leftBoundary, rightBoundary) {
      return this.availableArchiveFragments.filter(([dateStart, dateEnd]) => {
        return +leftBoundary < +dateEnd && +rightBoundary > +dateStart;
      }).map(([dateStart, dateEnd]) => {
        return [dateStart, dateEnd, "#bbbbbb", 0];
      });
    },
    async sendPTZRequest({action, code, camera_number}) {
      const payload = {
        action,
        camera_number,
      };
      if (code) {
        payload.code = code;
      }
      await this.$store.dispatch(`cameras/${ACTION_PTZ_CAMERA}`, payload);
    },
    async startPTZ(direction) {
      await this.sendPTZRequest({action: 'start', code: direction, camera_number: this.cameraInfo.number});
    },
    async stopPTZ() {
      clearInterval(this.ptzInterval);
      await this.sendPTZRequest({action: 'stop', code: '', camera_number:this.cameraInfo.number});
    },
    async centralizePTZ() {
      await this.sendPTZRequest({action: 'centralize', code: '', camera_number: this.cameraInfo.number});
    },
    /**
     * Установка камеры в текущую ячейку.
     * Установка таймера для отправки события в родительских компонент для обновление камеры (токена),
     * после чего произойдет изменение в передаваемом свойстве {@link initialCameraInfo}
     * и повторно установится таймер для уже нового экземпляра.
     *
     * Обновление камеры происходит через {@see loadCameraInfoForMultiMixin.methods.loadCameraInfo}, а там см. на использование кеша,
     * потому при расчете таймаута обновления используется {@see VUEX_CACHE_TTL}.
     *
     * @param {CameraInfo} cameraInfo
     */
    setupCamera(cameraInfo) {
      clearTimeout(this.refreshTimeoutId);
      this.cameraInfo = cameraInfo;
      if (!this.cameraInfo) {
        return;
      }
      if (this.cameraInfo.isReadyForLive) {
        this.refreshTimeoutId = setTimeout(() => {
          this.$emit("need-refresh", cameraInfo.number);
        }, whenToUpdateToken(TOKEN_TTL.MULTI, VUEX_CACHE_TTL));
      }
      if (this.showMediator) {
        this.receiveRecordingStatus();
      }
    },
    refreshCamera(cameraNumber) {
      this.$emit("need-refresh", cameraNumber);
    },
    async openOneScreenDialog(cameraNumber,cameraTitle) {
      if (document.fullscreenElement) {
        await exitFullscreen();
      }
      const cameraInfo = await this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_PLAYER}`, {
        number: cameraNumber,
        tokenLiveTTL: TOKEN_TTL.PLAYER,
        tokenDVRTTL: TOKEN_TTL.PLAYER,
      });

      if (!cameraInfo) {
        this.$camsdals.alert(this.$t('insufficientRights'));
        return;
      }

      const oneScreenDialogProps = {cameraInfo};
      if (this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]) {
        oneScreenDialogProps.initialTimeShift = new Date(+this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]);
      }
      if (this.$route.query[QUERY_KEY_ONE_SCREEN_TAB]) {
        oneScreenDialogProps.initialSelectedTab = this.$route.query[QUERY_KEY_ONE_SCREEN_TAB];
      }
      this.$camsdals.open(
        OneScreenDialog,
        oneScreenDialogProps,
        {dialogTitle: cameraTitle},
        {
          size: "vuedal-auto-width",
          name: "js-click-outside",
        },
      );
    },
    /**
     * Открытие диалога выбора камеры.
     *
     * Регистрируется обработчик при закрытии диалога, в который передается номер выбранной камеры
     * для ее загрузки через родительский компонент.
     */
    openSelectCameraDialog() {
      this.$camsdals.open(
        MeshFrameDialog,
        {},
        {dialogTitle: this.$t('selectCameraForCell')},
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside",
          onClose: (cameraNumber) => {cameraNumber && this.$emit("new-camera", cameraNumber);}
        }
      );
    },
    /**
     * Удаление из текущего элемента сетки камеры (прекращается трансляция).
     * Передача события о новом состоянии элемента сетки в родительский компонент.
     */
    deleteCamera() {
      this.$emit("new-camera", null);
      this.cameraInfo = null;
      clearTimeout(this.refreshTimeoutId);
      clearTimeout(this.timeoutIdForReceivingRecordingStatus);
    },
    /**
     * Получение доступных фрагментов видео от сервера. Периодический ее вызов для актуализации данных в плеере.
     */
    async receiveRecordingStatus() {
      if (!this.cameraInfo?.isAvailableArchive()) {
        return;
      }
      this.availableArchiveFragments = await this.$store.dispatch(`cameras/${ACTION_LOAD_RECORDING_STATUSES}`, {
        cameraNumber: this.cameraInfo.number,
        domain: this.cameraInfo.server.domain,
        token: this.cameraInfo.tokenDVR,
      });
      this.timeoutIdForReceivingRecordingStatus = setTimeout(this.receiveRecordingStatus, 30000);
    },
  },
};
</script>
