<template>
  <div class="event-playback-dialog">
    <SmartPlayer
      :archive-token="cameraInfo.tokenDVR"
      :available-archive-fragments="[[archiveFrom, archiveTo]]"
      :camera-number="cameraInfo.number"
      :vendor-name="cameraInfo.server.vendor_name"
      :domain="cameraInfo.server.domain"
      :expectation-archive-mode="false"
      :has-sound="cameraInfo.hasSound"
      :http-protocol="$store.getters.protocolVideoOverHTTP"
      :initial-time-shift="archiveFrom"
      :stream-count="cameraInfo.streamsCount"
      :use-simple-interface="true"
      :ws-protocol="$store.getters.protocolVideoOverWS"
      class="event-playback-dialog__player"
    />
    <p class="event-playback-dialog__caption" v-text="analyticMessage.summarize" />
  </div>
</template>

<script>
import {CameraInfo} from "@/store/cameras/index.js";
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {AnalyticMessage} from "@/store/analytics/analyticMessage.js";

/**
 * Вторичное диалоговое окно, которое нужно только для проигрывания в плеере заданного участка события.
 * Это окно всплывает поверх другого модального окна. Нужно учесть это и закрыть их в правильном порядке, чтоб не наслаивались.
 *
 * Так как компонент - прокси для плеера с заготовкой по временным рамкам, то здесь эмпирически подобраны временные смещения
 * для запроса участка видео. С определенного периода флюсоник отдает видео фрагменты длиной кратно 5 (+2+3 секунды).
 */
export default {
  name: "EventPlaybackDialog",
  components: {
    SmartPlayer,
  },
  props: {
    /**
     * Экземпляр {@link CameraInfo} по которому отображается информация в компоненте.
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Экземпляр {@link AnalyticMessage} по которому отображается информация в компоненте.
     */
    analyticMessage: {
      type: AnalyticMessage,
      required: true,
    },
  },
  computed: {
    /**
     * Начало отрезка времени для запроса архивного фрагмента видео.
     */
    archiveFrom() {
      const archiveFrom = new Date(+this.analyticMessage.startDate);
      archiveFrom.setSeconds(archiveFrom.getSeconds() - 15);
      return archiveFrom;
    },
    /**
     * Конец отрезка времени для запроса архивного фрагмента видео.
     */
    archiveTo() {
      const archiveTo = new Date(+this.analyticMessage.endDate);
      archiveTo.setSeconds(archiveTo.getSeconds() + 1);
      return archiveTo;
    },
  },
};
</script>


<style lang="scss">
@use "camsng-frontend-shared/styles/vars.scss" as vars;

.event-playback-dialog {
  max-width: 816px;
  &__player {
    width: 816px;
    height: 460px;
  }

  &__caption {
    font-family: vars.$cams-font-roboto-light;
    font-size: 13px;
  }
}
</style>
