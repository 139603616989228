/**
 * Описание маршрутов приложения.
 */

import MeshFramePage from "@/components/meshCameras/MeshFramePage.vue";
import MapFrame from "@/components/map/MapFrame.vue";
import MapTabFav from "@/components/map/MapTabFav.vue";
import MapTabMy from "@/components/map/MapTabMy.vue";
import MapTabPub from "@/components/map/MapTabPub.vue";
import MultiScreen from "@/components/multiScreen/MultiScreen.vue";

import {
  ROUTE_CAMS,
  ROUTE_CAMS_FAV,
  ROUTE_CAMS_MY,
  ROUTE_CAMS_SEARCH,
  ROUTE_EVENTS,
  ROUTE_MAP,
  ROUTE_MAP_FAV,
  ROUTE_MAP_MY,
  ROUTE_MAP_PUBLIC,
  ROUTE_MULTI_SCREEN,
  ROUTE_MULTI_SCREEN_MOSAIC,
  ROUTE_MULTI_SCREEN_NEW_MOSAIC,
  ROUTE_PACS,
  ROUTE_PACS_CARS,
  ROUTE_PACS_DEVICE_ACCESS_GROUPS,
  ROUTE_PACS_DEVICES,
  ROUTE_PACS_ANALYTICS_SETTINGS,
  ROUTE_PACS_EDIT_CAR,
  ROUTE_PACS_EDIT_CAR_NUMBER_ANALYTICS,
  ROUTE_PACS_EDIT_DEVICE,
  ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP,
  ROUTE_PACS_EDIT_EMPLOYEE,
  ROUTE_PACS_EMPLOYEES,
  ROUTE_PACS_EVENTS_CARS,
  ROUTE_PACS_EVENTS_MOTIONS,
  ROUTE_PACS_EVENTS_THERMALS,
  ROUTE_ROOT,
  SECTIONS_BY_ROUTES,
  ROUTE_PACS_CAR_NUMBER_ANALYTICS,
  ROUTE_PACS_EVENTS_HELMETS,
  ROUTE_PACS_EDIT_HELMET_ANALYTICS,
  ROUTE_PACS_HELMET_ANALYTICS,
  ROUTE_PACS_EVENTS_MASKS,
  ROUTE_PACS_EDIT_MASK_ANALYTICS,
  ROUTE_PACS_MASK_ANALYTICS,
  ROUTE_PACS_EVENTS_CROWDS,
  ROUTE_PACS_CROWD_ANALYTICS,
  ROUTE_PACS_EDIT_CROWD_ANALYTICS,
  ROUTE_PACS_EVENTS_PERIMETER_SECURITYS,
  ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS,
  ROUTE_PACS_EDIT_PERIMETER_SECURITY_ANALYTICS,
  ROUTE_SETUP,
  ROUTE_SETUP_CAMERAS,
  ROUTE_SETUP_EDIT_CAMERA,
  ROUTE_CAMS_MY_FOLDER,
  ROUTE_PACS_EDIT_PEOPLE_COUNT_ANALYTICS,
  ROUTE_PACS_PEOPLE_COUNT_ANALYTICS,
} from "@/router/names.js";
import store from "@/store/index.js";
import {MUTATION_ACTUALIZE_ROUTE_SECTION} from "@/store/mutations.js";
import Vue from "vue";
import VueRouter from "vue-router";
import PacsFrame from "@/components/pacs/PacsFrame.vue";
import EditEmployee from "@/components/pacs/employess/EditEmployee.vue";
import TableEmployees from "@/components/pacs/employess/TableEmployees.vue";
import TableDeviceAccessGroups from "@/components/pacs/deviceAccessGroups/TableDeviceAccessGroups.vue";
import TableDevices from "@/components/pacs/devices/TableDevices.vue";
import TableCars from "@/components/pacs/cars/TableCars.vue";
import EditCar from "@/components/pacs/cars/EditCar.vue";
import EditDeviceAccessGroup from "@/components/pacs/deviceAccessGroups/EditDeviceAccessGroup.vue";
import EditDevice from "@/components/pacs/devices/EditDevice.vue";

import TableThermalVision from "@/components/pacs/analytics/TableThermalVision.vue";
import TableCarNumber from "@/components/pacs/analytics/carNumber/TableCarNumber.vue";
import TableMotionAlarm from "@/components/pacs/analytics/TableMotionAlarm.vue";
import PacsEventsFrame from "@/components/pacs/analytics/PacsEventsFrame.vue";
import {QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER, QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/router/queryKeys.js";
import {APPLICATION_TITLE} from "@/utils/consts.js";
import PacsEditAnalyticsFrame from "@/components/pacs/analytics/PacsAnalyticsSettingsFrame.vue";
import TableCarNumberAnalytics from "@/components/pacs/analytics/carNumber/TableCarNumberAnalytics.vue";
import EditCarNumberAnalytics from "@/components/pacs/analytics/carNumber/EditCarNumberAnalytics.vue";
import TableHelmet from "@/components/pacs/analytics/helmet/TableHelmet.vue";
import TableHelmetAnalytics from "@/components/pacs/analytics/helmet/TableHelmetAnalytics.vue";
import EditHelmetAnalytics from "@/components/pacs/analytics/helmet/EditHelmetAnalytics.vue";
import TableMask from "@/components/pacs/analytics/mask/TableMask.vue";
import EditMaskAnalytics from "@/components/pacs/analytics/mask/EditMaskAnalytics.vue";
import TableMaskAnalytics from "@/components/pacs/analytics/mask/TableMaskAnalytics.vue";
import TableCrowd from "@/components/pacs/analytics/crowd/TableCrowd.vue";
import TableCrowdAnalytics from "@/components/pacs/analytics/crowd/TableCrowdAnalytics.vue";
import EditCrowdAnalytics from "@/components/pacs/analytics/crowd/EditCrowdAnalytics.vue";
import TablePerimeterSecurity from "@/components/pacs/analytics/perimeterSecurity/TablePerimeterSecurity.vue";
import TablePerimeterSecurityAnalytics from "@/components/pacs/analytics/perimeterSecurity/TablePerimeterSecurityAnalytics.vue";
import EditPerimeterSecurityAnalytics from "@/components/pacs/analytics/perimeterSecurity/EditPerimeterSecurityAnalytics.vue";
import SetupCamerasTable from "@/components/setupCameras/SetupCamerasTable.vue";
import EditCamera from "@/components/setupCameras/EditCamera.vue";
import SetupFrame from "@/components/setupCameras/SetupFrame.vue";
import TablePeopleCounterAnalytics from "@/components/pacs/analytics/peopleCounter/TablePeopleCounterAnalytics.vue";
import EditPeopleCounterAnalytics from "@/components/pacs/analytics/peopleCounter/EditPeopleCounterAnalytics.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: {name: ROUTE_ROOT},
    },
    { // Редирект с короткого URL на полноценный с открытием камеры.
      path: "/c/:cameraNumber/:timeShift?",
      redirect(to) {
        devLog(to.params);
        return {
          name: ROUTE_CAMS_MY,
          query: {
            [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: to.params.cameraNumber,
            [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: to.params.timeShift,
          }
        };
      },
    },
    {
      path: "/",
      name: ROUTE_ROOT,
      redirect: {name: ROUTE_CAMS_MY},
    },
    {
      path: "/cams",
      name: ROUTE_CAMS,
      redirect: {name: ROUTE_CAMS_MY},
    },
    {
      path: "/cams/my",
      name: ROUTE_CAMS_MY,
      component: MeshFramePage,
      meta: {
        title: `Мои камеры — камеры — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "/cams/folder/:folderId",
      name: ROUTE_CAMS_MY_FOLDER,
      component: MeshFramePage,
      meta: {
        title: `Мои камеры — камеры — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "/cams/fav",
      name: ROUTE_CAMS_FAV,
      component: MeshFramePage,
      meta: {
        title: `Избранное — камеры — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "/cams/search",
      name: ROUTE_CAMS_SEARCH,
      component: MeshFramePage,
      meta: {
        title: `Результаты поиска — камеры — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "/map",
      name: ROUTE_MAP,
      component: MapFrame,
      redirect: {name: ROUTE_MAP_MY},
      children: [
        {
          path: "my",
          name: ROUTE_MAP_MY,
          component: MapTabMy,
          meta: {
            title: `Мои камеры — карта — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "fav",
          name: ROUTE_MAP_FAV,
          component: MapTabFav,
          meta: {
            title: `Избранное — карта — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "public",
          name: ROUTE_MAP_PUBLIC,
          component: MapTabPub,
          meta: {
            title: `Публичные камеры — карта — ${APPLICATION_TITLE}`,
          },
        },
      ],
    },
    {
      path: "/multi",
      name: ROUTE_MULTI_SCREEN,
      component: MultiScreen,
    },
    {
      path: "/multi/new",
      name: ROUTE_MULTI_SCREEN_NEW_MOSAIC,
      component: MultiScreen,
      meta: {
        title: `Мультиэкран — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "/multi/:mosaicId(\\d+)",
      name: ROUTE_MULTI_SCREEN_MOSAIC,
      component: MultiScreen,
      meta: {
        title: `Мультиэкран — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "/pacs",
      name: ROUTE_PACS,
      component: PacsFrame,
      redirect: {name: ROUTE_PACS_EMPLOYEES},
      children: [
        {
          path: "employees",
          name: ROUTE_PACS_EMPLOYEES,
          component: TableEmployees,
          meta: {
            title: `СКУД — сотрудники — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "employee/:employeeId",
          name: ROUTE_PACS_EDIT_EMPLOYEE,
          component: EditEmployee,
          meta: {
            title: `СКУД — сотрудник — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "devices-access-groups",
          name: ROUTE_PACS_DEVICE_ACCESS_GROUPS,
          component: TableDeviceAccessGroups,
          meta: {
            title: `СКУД — группы доступа — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "device-access-group/:deviceAccessGroupId",
          name: ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP,
          component: EditDeviceAccessGroup,
          meta: {
            title: `СКУД — группа доступа — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "devices",
          name: ROUTE_PACS_DEVICES,
          component: TableDevices,
          meta: {
            title: `СКУД — устройства доступа — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "device/:deviceId",
          name: ROUTE_PACS_EDIT_DEVICE,
          component: EditDevice,
          meta: {
            title: `СКУД — устройство доступа — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "cars",
          name: ROUTE_PACS_CARS,
          component: TableCars,
          meta: {
            title: `СКУД — автомобили — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "car/:carId",
          name: ROUTE_PACS_EDIT_CAR,
          component: EditCar,
          meta: {
            title: `СКУД — автомобиль — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "events",
          name: ROUTE_EVENTS,
          component: PacsEventsFrame,
          redirect: {name: ROUTE_PACS_EVENTS_CARS},
          children: [
            {
              path: "thermals",
              name: ROUTE_PACS_EVENTS_THERMALS,
              component: TableThermalVision,
              meta: {
                title: `СКУД — события тепловизоров — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "cars",
              name: ROUTE_PACS_EVENTS_CARS,
              component: TableCarNumber,
              meta: {
                title: `СКУД — события распознавания номеров — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "motions",
              name: ROUTE_PACS_EVENTS_MOTIONS,
              component: TableMotionAlarm,
              meta: {
                title: `СКУД — события движения — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "helmets",
              name: ROUTE_PACS_EVENTS_HELMETS,
              component: TableHelmet,
              meta: {
                title: `СКУД — события по каскам — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "masks",
              name: ROUTE_PACS_EVENTS_MASKS,
              component: TableMask,
              meta: {
                title: `СКУД — события по маскам — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "perimeter",
              name: ROUTE_PACS_EVENTS_PERIMETER_SECURITYS,
              component: TablePerimeterSecurity,
              meta: {
                title: `СКУД — события по периметру — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "crowds",
              name: ROUTE_PACS_EVENTS_CROWDS,
              component: TableCrowd,
              meta: {
                title: `СКУД — события по скоплению людей — ${APPLICATION_TITLE}`,
              },
            },
          ],
        },
      ]
    },
    {
      path: "/setup/:cameraNumber",
      name: ROUTE_SETUP_EDIT_CAMERA,
      component: EditCamera,
      meta: {
        title: `Настройка камер — камеры — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "/setup",
      name: ROUTE_SETUP,
      component: SetupFrame,
      redirect: {name: ROUTE_SETUP_CAMERAS},
      children: [
        {
          path: "cameras",
          name: ROUTE_SETUP_CAMERAS,
          component: SetupCamerasTable,
          meta: {
            title: `Настройка камер — камеры — ${APPLICATION_TITLE}`,
          },
        },
        {
          path: "analytics-settings",
          name: ROUTE_PACS_ANALYTICS_SETTINGS,
          component: PacsEditAnalyticsFrame,
          redirect: {name: ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS},
          children: [
            {
              path: "car-numbers",
              name: ROUTE_PACS_CAR_NUMBER_ANALYTICS,
              component: TableCarNumberAnalytics,
              meta: {
                title: `СКУД — камеры распознавания номеров — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "car-numbers/:cameraNumber",
              name: ROUTE_PACS_EDIT_CAR_NUMBER_ANALYTICS,
              component: EditCarNumberAnalytics,
              meta: {
                title: `СКУД — аналитика распознавания номеров — редактирование — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "helmets",
              name: ROUTE_PACS_HELMET_ANALYTICS,
              component: TableHelmetAnalytics,
              meta: {
                title: `СКУД — камеры распознавания касок — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "helmets/:cameraNumber",
              name: ROUTE_PACS_EDIT_HELMET_ANALYTICS,
              component: EditHelmetAnalytics,
              meta: {
                title: `СКУД — аналитика распознавания касок — редактирование — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "masks",
              name: ROUTE_PACS_MASK_ANALYTICS,
              component: TableMaskAnalytics,
              meta: {
                title: `СКУД — камеры распознавания масок — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "masks/:cameraNumber",
              name: ROUTE_PACS_EDIT_MASK_ANALYTICS,
              component: EditMaskAnalytics,
              meta: {
                title: `СКУД — аналитика распознавания масок — редактирование — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "perimeters",
              name: ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS,
              component: TablePerimeterSecurityAnalytics,
              meta: {
                title: `Камеры вторжения в зону — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "perimeters/:cameraNumber",
              name: ROUTE_PACS_EDIT_PERIMETER_SECURITY_ANALYTICS,
              component: EditPerimeterSecurityAnalytics,
              meta: {
                title: `Аналитика вторжения в зону — редактирование — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "crowds",
              name: ROUTE_PACS_CROWD_ANALYTICS,
              component: TableCrowdAnalytics,
              meta: {
                title: `СКУД — камеры распознавания скопления людей — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "crowds/:cameraNumber",
              name: ROUTE_PACS_EDIT_CROWD_ANALYTICS,
              component: EditCrowdAnalytics,
              meta: {
                title: `СКУД — аналитика распознавания скопления людей — редактирование — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "people_counter",
              name: ROUTE_PACS_PEOPLE_COUNT_ANALYTICS,
              component: TablePeopleCounterAnalytics,
              meta: {
                title: `Подсчет посетителей — ${APPLICATION_TITLE}`,
              },
            },
            {
              path: "people_counter/:cameraNumber",
              name: ROUTE_PACS_EDIT_PEOPLE_COUNT_ANALYTICS,
              component: EditPeopleCounterAnalytics,
              meta: {
                title: `Подсчет посетителей — редактирование — ${APPLICATION_TITLE}`,
              },
            },
          ],
        },
      ]
    },
  ],

});

/**
 * Глобальный хук при навигации.
 *
 * При изменении URL в store устанавливается имя маршрута, на который был осуществлен переход в рамках конкретного раздела.
 * Этот маршрут будет использоваться для главного навигационного меню, чтобы клик по ссылке перенаправлял пользователя
 * на последнюю открытую страницу в рамках того самого раздела.
 * Почему отслеживание происходит глобально?
 * Отслеживать изменения в URL и корректировать маршруты в меню можно непосредственно в самом компоненте (Сейчас это {@link Sidebar}),
 * однако компонентов меню может быть больше (например для мобильной версии), а текущий загружен может и не быть.
 *
 * Корректируется title документа в соотвествии с настройками в маршрутах.
 */
router.beforeEach((to, from, next) => {
  let nameRouteSection = SECTIONS_BY_ROUTES[to.name];
  if (nameRouteSection) {
    store.commit(MUTATION_ACTUALIZE_ROUTE_SECTION, {
      nameRouteSection: nameRouteSection,
      routeTo: {name: to.name, params: to.params, query: to.query}
    });
  }
  document.title = to.meta.title;
  next();
});

export default router;
