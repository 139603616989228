<template>
  <div class="common-container__body" style="border: none;margin-left: 10px;">
    <SpinnerLoadingModal v-if="isLoading" />
    <p v-else-if="errorLoadEntity">
      Ошибка: невозможно отобразить информацию.
    </p>

      <br>
        <CamsButton
          title="Открыть плеер для просмотра и редактирования зон аналитики"
          type="button"
          @click="openDialogEditZones()"
        >
          Редактировать аналитику
        </CamsButton>
      </div>
</template>

<script>
import {editEntityMixin} from "@/components/pacs/mixins.js";
import {
  ACTION_EDIT_PEOPLE_COUNT_ANALYTICS,
  ACTION_LOAD_PEOPLE_COUNT_ANALYTICS_FOR_EDIT,
  CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT
} from "@/store/analytics/index.js";
import {ACTION_LOAD_CAMERA_FOR_EDIT_ANALYTICS, FIELDS_CAMERA} from "@/store/cameras/index.js";
import {TOKEN_TTL} from "@/utils/consts.js";
import RequestTokenCameraDialog from "@/components/pacs/analytics/RequestTokenCameraDialog.vue";
import {AnalyticLinesConfig,AnalyticLine} from "camsng-frontend-shared/components/smartPlayer/mixins";

export default {
  name: "EditPerimeterSecuritysAnalytics",
  mixins: [editEntityMixin],
  data() {
    const cameraNumber = this.$route.params.cameraNumber;

    return {
      entityId: cameraNumber,
      actionForLoadEntity: `analytics/${ACTION_LOAD_PEOPLE_COUNT_ANALYTICS_FOR_EDIT}`,
      actionForSave: `analytics/${ACTION_EDIT_PEOPLE_COUNT_ANALYTICS}`,
      numbers: [],
    };
  },
  methods: {
    /**
     * Загрузка информации по конкретной камере.
     */
    async afterLoadSourceData() {
      this.dataForm = {
        cameraNumber: this.sourceData.entityInfo[CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT.fields.camera_number.name],
        counterLine: this.sourceData.entityInfo[CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT.fields.counter_line.name],
        enterPoint: this.sourceData.entityInfo[CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT.fields.enter_point.name],
        imageWidth: this.sourceData.entityInfo[CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT.fields.image_width.name],
        imageHeight: this.sourceData.entityInfo[CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT.fields.image_height.name],
      };
    },
    /**
     * Сохранение данных и продолжение редактирования.
     * После успешного сохранения данных происходит перезагрузка исходных данных.
     */
    async saveDataAnalytics() {
      try {
        await this.saveData();
        await this.loadSourceData();
        this.$notify({
          group: "main",
          text: this.$t('saveSuccess'),
          duration: 5000,
          type: "success"
        });
      } catch {
        this.$notify({
          group: "main",
          text: this.$t('errorOccurred'),
          duration: 5000,
          type: "error"
        });
      }
    },
    /**
     * Открытие формы для редактирования зон аналитики.
     */
    async openDialogEditZones() {
      const response = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_EDIT_ANALYTICS}`, {
        fields: [
          FIELDS_CAMERA.number,
          FIELDS_CAMERA.address,
          FIELDS_CAMERA.title,
          FIELDS_CAMERA.is_embed,
          FIELDS_CAMERA.analytics,
          FIELDS_CAMERA.is_fav,
          FIELDS_CAMERA.is_public,
          FIELDS_CAMERA.server,
          FIELDS_CAMERA.streams_count,
          FIELDS_CAMERA.token_l,
          FIELDS_CAMERA.token_r,
          FIELDS_CAMERA.token_d,
          FIELDS_CAMERA.permission,
        ],
        tokenDVRTTL: TOKEN_TTL.MULTI,
        tokenDownloadStart: 1,
        numbers: [this.dataForm.cameraNumber],
      });
      const cameraInfo = response.data.results[0];
      const linesInfoForEdit =[this.dataForm.counterLine, [this.dataForm.enterPoint]] || [],
            analyticLinesConfigForEdit = new AnalyticLinesConfig(
              this.dataForm.imageWidth,
              this.dataForm.imageHeight,
              1,
              3,
              [linesInfoForEdit].map((rawAnalyticLine) => new AnalyticLine(rawAnalyticLine)));
      console.log(linesInfoForEdit)
      this.$camsdals.open(
        RequestTokenCameraDialog,
        {
          archiveToken: cameraInfo.token_r,
          downloadToken: cameraInfo.token_d,
          liveToken: cameraInfo.token_l,
          cameraNumber: this.entityId,
          title: cameraInfo.title,
          domain: cameraInfo.server.domain,
          streamCount: cameraInfo.streams_count,
          hasSound: cameraInfo.is_sounding,
          vendorName: cameraInfo.server.vendor_name,
          analyticLinesConfig: analyticLinesConfigForEdit,
        },
        {dialogTitle: `Просмотр видео с камеры №${this.entityId} - ${cameraInfo.title}`},
        {
          name: "js-click-outside",
          size: "lg",
          onClose: async (analyticLinesConfigForSave) => {
            if (!analyticLinesConfigForSave) {
              return;
            }
            this.dataForm.imageWidth = analyticLinesConfigForSave.linesWidth;
            this.dataForm.imageHeight  = analyticLinesConfigForSave.linesHeight;
            this.dataForm.counterLine = analyticLinesConfigForSave.lines[0].points[0]
              .map((point) => ({x: Math.trunc(point.x), y: Math.trunc(point.y)}));
            let tempEnterPoint =analyticLinesConfigForSave.lines[0].points[1]
              .map((point) => ({x: Math.trunc(point.x), y: Math.trunc(point.y)}));
            this.dataForm.enterPoint = tempEnterPoint[0];
            await this.saveData();

            // this.errorsFormEdit[this.fieldsEntity.lines] = this.errorsFormEdit[this.fieldsEntity.lines].join(", ");
          },
        }
      );
    },
  }
};
</script>
