<template>
  <div class="map-frame">
    <MapLinks />
    <div class="map-frame__map">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import MapLinks from "@/components/map/MapLinks.vue";

/**
 * Страница раздела "карты".
 */
export default {
  name: "MapFrame",
  components: {
    MapLinks,
  },
};
</script>

<style lang="scss">
@use "./scss/index.scss";
</style>
