/**
 * Перечень имен для маршрутов навигации в приложении.
 */

export const ROUTE_ROOT = "ROOT";
export const ROUTE_CAMS = "CAMS";
export const ROUTE_CAMS_MY = "CAMS_MY";
export const ROUTE_CAMS_FAV = "CAMS_FAV";
export const ROUTE_CAMS_SEARCH = "CAMS_SEARCH";
export const ROUTE_MAP = "MAP";
export const ROUTE_MAP_MY = "MAP_MY";
export const ROUTE_MAP_FAV = "MAP_FAV";
export const ROUTE_MAP_PUBLIC = "MAP_PUBLIC";
export const ROUTE_MULTI_SCREEN = "MULTI_SCREEN";
export const ROUTE_MULTI_SCREEN_MOSAIC = "MULTI_SCREEN_MOSAIC";
export const ROUTE_MULTI_SCREEN_NEW_MOSAIC = "MULTI_SCREEN_NEW_MOSAIC";
export const ROUTE_PACS = "PACS";
export const ROUTE_PACS_EMPLOYEES = "PACS_EMPLOYEES";
export const ROUTE_PACS_EDIT_EMPLOYEE = "PACS_EDIT_EMPLOYEE";
export const ROUTE_PACS_DEVICE_ACCESS_GROUPS = "PACS_DEVICE_ACCESS_GROUPS";
export const ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP = "PACS_EDIT_DEVICE_ACCESS_GROUP";
export const ROUTE_PACS_DEVICES = "PACS_DEVICES";
export const ROUTE_PACS_EDIT_DEVICE = "PACS_EDIT_DEVICE";
export const ROUTE_PACS_CARS = "PACS_CARS";
export const ROUTE_PACS_EDIT_CAR = "PACS_EDIT_CAR";
export const ROUTE_EVENTS = "ROUTE_EVENTS";
export const ROUTE_PACS_EVENTS_THERMALS = "PACS_EVENTS_THERMALS";
export const ROUTE_PACS_EVENTS_CARS = "PACS_EVENTS_CARS";
export const ROUTE_PACS_EVENTS_MOTIONS = "PACS_EVENTS_MOTION";
export const ROUTE_PACS_EVENTS_HELMETS = "PACS_EVENTS_HELMETS";
export const ROUTE_PACS_EVENTS_MASKS = "PACS_EVENTS_MASKS";
export const ROUTE_PACS_EVENTS_PERIMETER_SECURITYS = "PACS_EVENTS_PERIMETER_SECURITYS";
export const ROUTE_PACS_EVENTS_CROWDS = "PACS_EVENTS_CROWDS";
export const ROUTE_PACS_ANALYTICS_SETTINGS = "PACS_ANALYTICS_SETTINGS";
export const ROUTE_PACS_CAR_NUMBER_ANALYTICS = "PACS_CAR_NUMBER_ANALYTICS";
export const ROUTE_PACS_EDIT_CAR_NUMBER_ANALYTICS = "PACS_EDIT_CAR_NUMBER_ANALYTICS";
export const ROUTE_PACS_HELMET_ANALYTICS = "PACS_HELMET_ANALYTICS";
export const ROUTE_PACS_EDIT_HELMET_ANALYTICS = "PACS_EDIT_HELMET_ANALYTICS";
export const ROUTE_PACS_MASK_ANALYTICS = "PACS_MASK_ANALYTICS";
export const ROUTE_PACS_EDIT_MASK_ANALYTICS = "PACS_EDIT_MASK_ANALYTICS";
export const ROUTE_PACS_CROWD_ANALYTICS = "PACS_CROWD_ANALYTICS";
export const ROUTE_PACS_EDIT_CROWD_ANALYTICS = "PACS_EDIT_CROWD_ANALYTICS";
export const ROUTE_PACS_PEOPLE_COUNT_ANALYTICS = "PACS_PEOPLE_COUNT_ANALYTICS";
export const ROUTE_PACS_EDIT_PEOPLE_COUNT_ANALYTICS = "PACS_EDIT_PEOPLE_COUNT_ANALYTICS";
export const ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS = "PACS_PERIMETER_SECURITY_ANALYTICS";
export const ROUTE_PACS_EDIT_PERIMETER_SECURITY_ANALYTICS = "PACS_EDIT_PERIMETER_SECURITY_ANALYTICS";
export const ROUTE_SETUP = "SETUP";
export const ROUTE_SETUP_CAMERAS = "SETUP_CAMERAS";
export const ROUTE_SETUP_EDIT_CAMERA = "SETUP_EDIT_CAMERA";
export const ROUTE_CAMS_MY_FOLDER = "CAMS_MY_FOLDER";


/**
 * Группировка имен маршрутов по секциям.
 * Все маршруты ссылаются на необходимый (корневой) маршрут.
 */
export const SECTIONS_BY_ROUTES = Object.freeze({
  // Раздел камер.
  [ROUTE_CAMS]: ROUTE_CAMS,
  [ROUTE_CAMS_MY]: ROUTE_CAMS,
  [ROUTE_CAMS_FAV]: ROUTE_CAMS,
  [ROUTE_CAMS_SEARCH]: ROUTE_CAMS,
  [ROUTE_CAMS_MY_FOLDER]: ROUTE_CAMS,
  // Раздел карты.
  [ROUTE_MAP]: ROUTE_MAP,
  [ROUTE_MAP_MY]: ROUTE_MAP,
  [ROUTE_MAP_FAV]: ROUTE_MAP,
  [ROUTE_MAP_PUBLIC]: ROUTE_MAP,
  // Раздел мультиэкрана.
  [ROUTE_MULTI_SCREEN]: ROUTE_MULTI_SCREEN,
  [ROUTE_MULTI_SCREEN_MOSAIC]: ROUTE_MULTI_SCREEN,
  [ROUTE_MULTI_SCREEN_NEW_MOSAIC]: ROUTE_MULTI_SCREEN,
  // Раздел СКУД.
  [ROUTE_PACS]: ROUTE_PACS,
  [ROUTE_PACS_EMPLOYEES]: ROUTE_PACS_EMPLOYEES,
  [ROUTE_PACS_DEVICE_ACCESS_GROUPS]: ROUTE_PACS_DEVICE_ACCESS_GROUPS,
  [ROUTE_PACS_DEVICES]: ROUTE_PACS_DEVICES,
  [ROUTE_PACS_CARS]: ROUTE_PACS_CARS,
  [ROUTE_EVENTS]: ROUTE_EVENTS,
  [ROUTE_PACS_EVENTS_THERMALS]: ROUTE_PACS_EVENTS_THERMALS,
  [ROUTE_PACS_EVENTS_CARS]: ROUTE_PACS_EVENTS_CARS,
  [ROUTE_PACS_EVENTS_MOTIONS]: ROUTE_PACS_EVENTS_MOTIONS,
  [ROUTE_PACS_EVENTS_HELMETS]: ROUTE_PACS_EVENTS_HELMETS,
  [ROUTE_PACS_EVENTS_MASKS]: ROUTE_PACS_EVENTS_MASKS,
  [ROUTE_PACS_EVENTS_PERIMETER_SECURITYS]: ROUTE_PACS_EVENTS_PERIMETER_SECURITYS,
  [ROUTE_PACS_EVENTS_CROWDS]: ROUTE_PACS_EVENTS_CROWDS,
  [ROUTE_PACS_ANALYTICS_SETTINGS]: ROUTE_PACS_ANALYTICS_SETTINGS,
  [ROUTE_PACS_CAR_NUMBER_ANALYTICS]: ROUTE_PACS_CAR_NUMBER_ANALYTICS,
  [ROUTE_PACS_EDIT_CAR_NUMBER_ANALYTICS]: ROUTE_PACS_EDIT_CAR_NUMBER_ANALYTICS,
  [ROUTE_PACS_HELMET_ANALYTICS]: ROUTE_PACS_HELMET_ANALYTICS,
  [ROUTE_PACS_EDIT_HELMET_ANALYTICS]: ROUTE_PACS_EDIT_HELMET_ANALYTICS,
  [ROUTE_PACS_MASK_ANALYTICS]: ROUTE_PACS_MASK_ANALYTICS,
  [ROUTE_PACS_EDIT_MASK_ANALYTICS]: ROUTE_PACS_EDIT_MASK_ANALYTICS,
  [ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS]: ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS,
  [ROUTE_PACS_PEOPLE_COUNT_ANALYTICS]: ROUTE_PACS_PEOPLE_COUNT_ANALYTICS,
  [ROUTE_PACS_EDIT_PEOPLE_COUNT_ANALYTICS]: ROUTE_PACS_EDIT_PEOPLE_COUNT_ANALYTICS,
  [ROUTE_PACS_EDIT_PERIMETER_SECURITY_ANALYTICS]: ROUTE_PACS_EDIT_PERIMETER_SECURITY_ANALYTICS,
  [ROUTE_PACS_CROWD_ANALYTICS]: ROUTE_PACS_CROWD_ANALYTICS,
  [ROUTE_PACS_EDIT_CROWD_ANALYTICS]: ROUTE_PACS_EDIT_CROWD_ANALYTICS,
  //Раздел с  настройками камер
  [ROUTE_SETUP]: ROUTE_SETUP,
  [ROUTE_SETUP_CAMERAS]: ROUTE_SETUP_CAMERAS,
  [ROUTE_SETUP_EDIT_CAMERA]: ROUTE_SETUP_EDIT_CAMERA,

});
