<template>
  <div class="frame-container">
    <Sidebar />
    <main class="main-frame cams-container">
      <TopMenu class="main-frame__top-menu" />
      <router-view class="main-frame__content" />
    </main>

    <div v-if="isOldBrowser" class="oldbrowser-container">
      <div>
        <strong>Вы зашли на сайт через устаревший браузер</strong>, некоторые элементы могут отображаться некорректно.
        Используйте последние версии современных браузеров, например:
        <a href="https://www.mozilla.org/ru/firefox/" target="_blank">Mozilla Firefox</a>,
        <a href="https://www.google.ru/chrome/" target="_blank">Google Chrome</a>,
        <a href="https://browser.yandex.ru/" target="_blank">Яндекс.Браузер</a>,
        <a href="https://www.opera.com/ru" target="_blank">Opera</a>
      </div>
      <svg class="icon icon-close" @click="isOpenOldBrowserWarning = false">
        <use xlink:href="#icon-close2" />
      </svg>
    </div>

    <notifications classes="customized-notification-style" group="main" position="top right" />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import TopMenu from "@/components/TopMenu.vue";
// import {matchesUA} from "browserslist-useragent";
import {mapState} from "vuex";
import OneScreenDialog from "@/components/oneScreen/OneScreenDialog.vue";
import {ACTION_LOAD_CAMERA_FOR_PLAYER} from "@/store/cameras/index.js";
import {QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER, QUERY_KEY_ONE_SCREEN_TAB, QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/router/queryKeys.js";
import {TOKEN_TTL} from "@/utils/consts.js";

/**
 * Общий компонент с главным меню и блоком контента, который заполняется в зависимости от работы роутера
 * и зафиксированного типа модального окна.
 *
 * Из компонента происходит открытие диалогового окна с плеером. Открытие диалога происходит по причине смены ключевых параметров в URL,
 * которые здесь отслеживаются.
 */
export default {
  name: "MainFrame",
  components: {
    Sidebar,
    TopMenu,
  },
  data() {
    return {
      isOpenOldBrowserWarning: true,
    };
  },
  computed: {
    ...mapState({
      notificationQueue: state => state.notificationQueue,
      sharedTimeShift: state => state.sharedTimeShift,
    }),
    /**
     * @return {Boolean} Вернет true для клиентов с устаревшими браузерами.
     * Пока что отключено за ненадобностью, 08.09.2023
     * IE поддерживать нет смсысла, остальные браузеры работают
     */
    isOldBrowser() {
      return false;
      // this.isOpenOldBrowserWarning && !matchesUA(navigator.userAgent, {
      //   // Браузерная версия библиотеки не поддерживает статистику по странам.
      //   // BrowserslistError: Country statistics is not supported in client-side build of Browserslist
      //   browsers: ["> 1%", "last 2 versions", "not ie <= 10"],
      //   allowHigherVersions: true,
      // });
    },
  },
  watch: {
    /**
     * Очередь сообщений для пользователя, туда можно добавлять сообщения, например, из store.
     */
    notificationQueue: {
      /**
       * Если в очереди сообщений есть элементы берем верхний и показываем его
       */
      handler: function (notificationQueue) {
        const queueTop = notificationQueue[notificationQueue.length - 1];
        this.$notify({...queueTop, group: "main"});
      },
      immediate: true,
      deep: true,
    },
    /**
     * При изменении значения метки времени в хранилище меняем значение в url.
     *
     * @param {Date} newValue
     */
    sharedTimeShift(newValue) {
      /**
       * Для избежания распространенной ошибки NavigationDuplicated
       * проверяем передаваемые в роутер значения параметра запроса.
       */
      if (this.$route.query && this.$route.query[QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER] && +newValue !== +this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]) {
        this.$router.replace({
          query: {
            ...(this.$route.query || {}),
            [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +newValue
          }
        });
      }
    },
    /**
     * Отслеживаем наличие в маршруте параметра number (номер камеры) для открытия диалога с камерой.
     *
     * @param {Object} to
     * @param {Object} from
     */
    "$route"(to, from) {
      if (to.query[QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER] && from.query[QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER] !== to.query[QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]) {
        this.openOneScreenDialog();
      }
    },
  },
  /**
   * Отслеживание GET параметров в URL чтобы при запуске приложения сразу открыть диалог с камерой, если нужно.
   */
  mounted() {
    if (this.$route.query[QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]) {
      this.openOneScreenDialog();
    }
  },
  methods: {
    /**
     * Открытие модального окна с трансляцией/архивом.
     */
    async openOneScreenDialog() {
      // Для получения информации по камере параметром передается номер камеры, являющийся частью url.
      const cameraInfo = await this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_PLAYER}`, {
              number: this.$route.query[QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER],
              tokenLiveTTL: TOKEN_TTL.PLAYER,
              tokenDVRTTL: TOKEN_TTL.PLAYER,
            }),
            // Заготовка параметров URL на случай если камера недоступна то строку URL надо обновить, убрав из нее лишнее,
            // а то предупреждение покажется, пользователь закроет окно с ошибкой а URL не поменяется, и при перезагрузке страницы опять будет ошибка.
            queryParamsIfFail = _.omit(this.$route.query, [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER, QUERY_KEY_ONE_SCREEN_TIME_SHIFT, QUERY_KEY_ONE_SCREEN_TAB]);

      if (!cameraInfo) {
        this.$camsdals.alert(this.$t('insufficientRights'));
        this.$router.replace({name: this.$route.name, query: queryParamsIfFail});
        return;
      }

      if (!cameraInfo.isReadyForLive) {
        this.$camsdals.alert(this.$t('liveStreamTokenError'));
        this.$router.replace({name: this.$route.name, query: queryParamsIfFail});
        return;
      }

      if (this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT] && !cameraInfo.isReadyForArchive) {
        this.$camsdals.alert(this.$t('archiveTokenError'));
        this.$router.replace({name: this.$route.name, query: queryParamsIfFail});
        return;
      }

      const oneScreenDialogProps = {cameraInfo};
      // Если есть таймкод, то в настройки добавляем метку времени.
      if (this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]) {
        oneScreenDialogProps.initialTimeShift = new Date(+this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]);
      }
      /**
       * Для компонента OneScreenDialog реализована возможность переключения между вкладками.
       * В случае необходимости отображения OneScreenDialog на конкретной вкладки возможно
       * передать специальный параметр в router, а затем и в сам компонент OneScreenDialog.
       */
      if (this.$route.query[QUERY_KEY_ONE_SCREEN_TAB]) {
        oneScreenDialogProps.initialSelectedTab = this.$route.query[QUERY_KEY_ONE_SCREEN_TAB];
      }

      this.$camsdals.open(
        OneScreenDialog,
        oneScreenDialogProps,
        {dialogTitle: cameraInfo.title},
        {
          size: "vuedal-auto-width",
          name: "js-click-outside",
          /**
           * Закрытие диалога с возвратом к компоненту за которым закреплен текущий маршрут.
           */
          onClose: () => {
            const queryParams = _.omit(this.$route.query, [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER, QUERY_KEY_ONE_SCREEN_TIME_SHIFT, QUERY_KEY_ONE_SCREEN_TAB]);
            this.$router.push({name: this.$route.name, query: queryParams});
          }
        }
      );
    },
  }
};
</script>

<style lang="scss">
@use "./scss/main-frame.scss";
</style>
