<template>
  <div class="event-screenshot-dialog">
    <img v-if="!showError" :src="fullScreenshot" :alt="$t('screenshotAlt')" @error="showError = true">
    <h4 v-else>
      {{ $t('screenshotProcessing') }}
    </h4>
    <p class="event-screenshot-dialog__caption" v-text="analyticMessage.summarize" />
  </div>
</template>

<script>
import {CameraInfo} from "@/store/cameras/index.js";
import {ACTION_LOAD_FULL_SCREENSHOT_EVENT} from "@/store/analytics/index.js";
import {AnalyticMessage} from "@/store/analytics/analyticMessage.js";

/**
 * Вторичное диалоговое окно, которое нужно только для отображения скриншота события.
 * Это окно всплывает поверх другого модального окна. Нужно учесть это и закрыть их в правильном порядке, чтоб не наслаивались.
 */
export default {
  name: "EventScreenshotDialog",
  props: {
    /**
     * Экземпляр {@link CameraInfo} c любым токеном для просмотра скриншота события по этой камере.
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Экземпляр {@link AnalyticMessage} из которого извлекается URL полного скриншота.
     */
    analyticMessage: {
      type: AnalyticMessage,
      required: true
    }
  },
  data() {
    return {
      showError: false,
      fullScreenshot: ""
    };
  },
  /**
   * Получение скриншота из сообщения события.
   * Сообщение может быть получено по каналам не предусматривающих извлечение URL напрямую из сообщения (SSE на прямой трансляции),
   * тогда в общем случае нужно это проверять и в случае отсутствия запрашивать по API.
   */
  created() {
    this.$store.dispatch(`analytics/${ACTION_LOAD_FULL_SCREENSHOT_EVENT}`, {
      analyticMessage: this.analyticMessage,
      screenshotToken: this.cameraInfo && (this.cameraInfo.tokenLive || this.cameraInfo.tokenDVR),
    })
      .then((fullScreenshot) => {
        this.fullScreenshot = fullScreenshot;
      });
  }
};
</script>

<style lang="scss">
@use "camsng-frontend-shared/styles/vars.scss" as vars;

.event-screenshot-dialog {
  &__caption {
    font-family: vars.$cams-font-roboto-light;
    font-size: 13px;
  }
}
</style>
