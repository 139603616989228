<template>
  <div class="common-container__body">
    <div class="common-container__wrap">
      <div class="main__header">
        <div class="tabs-container">
          <router-link :to="{ name: routes.ROUTE_SETUP_CAMERAS }" class="tabs-container__tab-item">
            Настройка камер
          </router-link>
          <router-link :to="{name: routes.ROUTE_PACS_ANALYTICS_SETTINGS }" class="tabs-container__tab-item">
            Настройка аналитик
          </router-link>
        </div>
      </div>

      <router-view />
    </div>
  </div>
</template>

<script>
import {
  ROUTE_PACS_ANALYTICS_SETTINGS,
   ROUTE_SETUP_CAMERAS
} from "@/router/names.js";

export default {
  name: "SetupFrame",
  data() {
    return {
      routes: {
        ROUTE_SETUP_CAMERAS,
        ROUTE_PACS_ANALYTICS_SETTINGS,
      },
    };
  },
};
</script>

<style lang="scss">
@use "./style.scss";
</style>
